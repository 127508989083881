<template>
  <div>
    <v-container class="mt-n2">
      <v-row class="align-center justify-center">
        <v-btn
          color="info"
          text
          outlined
          :to="{
            name: 'Timetable',
            query: { code: codes },
          }"
          >Verfügbarkeit FS {{ item.code }}</v-btn
        >

        <v-spacer />
        <DivisionFilter v-model="divisions"></DivisionFilter>
        <v-spacer></v-spacer>
        <v-switch v-model="stvs" inset label="Stvs" />
      </v-row>
    </v-container>
    <v-card :loading="loading">
      <v-system-bar>Fachschaft {{ item.description }}</v-system-bar>

      <v-divider />
      <v-list subheader>
        <v-subheader v-if="itemsHead.length > 0">Vorsitz</v-subheader>
        <PersonItem
          v-for="teacher in itemsHead"
          :value="teacher"
          :key="teacher.id"
        >
        </PersonItem>
        <v-subheader v-if="itemsMembers.length > 0">Mitglieder</v-subheader>
        <PersonItem
          v-for="teacher in itemsMembers"
          :value="teacher"
          :key="teacher.id"
        ></PersonItem>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DivisionFilter from "@/components/DivisionFilter.vue";
import PersonItem from "common/components/PersonItem.vue";
export default defineComponent({
  name: "DepartmentDetail",
  components: { DivisionFilter, PersonItem },
  props: ["id"],
  data() {
    return {
      divisions: [],
      item: {},
      loading: false,
      search: "",
      stvs: false,
    };
  },
  computed: {
    codes() {
      var members = this.itemsHead.concat(this.itemsMembers);
      return members.map((el) => el.code);
    },
    itemsHead() {
      return this.item.heads ? this.item.heads.filter(this.filter) : [];
    },
    itemsMembers() {
      return this.item.members ? this.item.members.filter(this.filter) : [];
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    filter(el) {
      return (
        (this.stvs || el.code.length == 3) &&
        (this.divisions.length == 0 ||
          this.divisions.includes(0) ||
          el.division == null ||
          this.divisions.includes(el.division.id))
      );
    },
    async fetchData() {
      if (this.id) {
        this.loading = true;
        this.item = await this.apiGet({
          resource: "person/department",
          id: this.id,
        });
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
